
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import EditChallan from "./EditChallan";
import PDFIMG from '../../Config/pdf.svg';
import EXCELIMG from '../../Config/excel.svg';
import { Link } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from '../../Config/Pagination';
import ViewChallan from "./ViewChallan";


export default function DeliveryChalan() {

    const [challanData, setChallanData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    // const [challanViewID, setChallanViewID] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetChallans`);
                    if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setChallanData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setChallanData([]);
                }
            } catch (error) {
                    console.error("Error fetching data:", error);
            } finally {
                    setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const handleReload =() => {
        window.location.reload();
    }

    // const showChallanViewModal = (itemId) => {
    //     setChallanViewID(itemId);
    //     console.log(itemId)
    // };

    const itemsPerPage = 10;

    const filteredItems = challanData.filter(item => 
        item.site_address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.weight && item.weight.toLowerCase().includes(searchTerm.toLowerCase()))  
        // (item.customer_name && item.customer_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    
    // Calculate indices for slicing data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <Base>
            
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Delivery Chalan</h4>
                        <h6>Manage your chalans</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <div className="search-container">
                            <input 
                                type="text" 
                                name="search" 
                                placeholder="Search..." 
                                className="search-input"
                                // value={searchTerm}
                                // onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <a href="#" className="search-btn">
                                <i className="fas fa-search fs-6"></i>      
                            </a>
                        </div>                  
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"><img src={PDFIMG} alt="img"/></a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"><img src={EXCELIMG} alt="img"/></a>
                    </li>
                    <li>
                        <a title="Print"><i className="fa-solid fa-print fs-5"></i></a>
                    </li>
                    <li>
                        <a title="Refresh" onClick={handleReload}><i className="fa-solid fa-arrow-rotate-left fs-5"></i></a>
                    </li>
                    <li>
                        <a title="Collapse" id="collapse-header"><i className="fa-solid fa-chevron-up fs-6"></i></a>
                    </li>
                    {/* <li>
                        <a className="btn btn-added" data-bs-toggle="modal" data-bs-target="#add-logistic-rate"><i className="fa-solid fa-plus"></i></a>
                    </li> */}
                    <li>
                        <Link to='/add-challan' className="btn btn-added"><i className="fa-solid fa-plus"></i></Link>
                    </li>
                </ul>
                <div className="d-flex">
                </div>
            </div>
            
            <div className="card table-list-card">
                <div className="card-body">
                   
                    <div className="table-responsive product-list d-none d-lg-block d-md-block">
                        <table className="table datanew">
                            <thead>
                                <tr>
                                    <th className="no-sort">#</th>
                                    <th>Number</th>
                                    <th>Customer</th>
                                    <th>Project</th>
                                    <th>Date</th>
                                    <th>Distance</th>
                                    <th className="no-sort">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {loading ? 
                                    <td colSpan={12} className="text-center">
                                        <p className="text-center">
                                            <div class="containers m-auto">
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                            </div>
                                        </p>
                                    </td>
                                :
                                currentItems && currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{indexOfFirstItem + index + 1}</td>
                                        <td>CH-{item.delivery_id} </td>
                                        <td>{item.cus_name}</td>
                                        <td>{item.proj_name}</td>
                                        <td>
                                            {new Date(item.date_c).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                            }).replace(/\//g, '-')}
                                        </td>
                                        <td>{item.distance}</td>
                                        <td className="text-start">
                                            <div className="dropdown">
                                                <a className="action-set" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                </a>
                                                <div className="dropdown-menu">
                                                    <Link
                                                        to={`/view-challan/${item.delivery_id}`}
                                                        className="dropdown-item" 
                                                    >
                                                        <i className="fa-regular fa-eye me-2"></i>View
                                                    </Link>
                                                    <Link 
                                                        to={`/edit-challan/${item.delivery_id}`}
                                                        className={`dropdown-item`}
                                                    >
                                                        <i className="fa-regular fa-pen-to-square text-info me-2"></i>Edit 
                                                    </Link>
                                                    {/* <a
                                                        className="dropdown-item"
                                                        onClick={() => toggleActiveStatus(item.id, item.active)}
                                                    >
                                                        <i className={`fa-solid fa-toggle-${item.active ? 'off text-danger' : 'on text-success'} me-2`}></i>
                                                        {item.active ? 'Deactivate' : 'Activate'}
                                                    </a> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={challanData.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>

                    {/* Mobiel view */}
                    <div className="row d-block d-md-none d-lg-none">
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                            <div className="employee-grid-profile">
                                <div className="profile-head">
                                    <label className="checkboxs">
                                        <input type="checkbox"/>
                                        <span className="checkmarks"></span>
                                    </label>
                                    <div className="profile-head-action">
                                        <span className="badge bg-outline-warning text-center w-auto me-1">Pending</span>
                                        <div className="dropdown profile-action">
                                            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    className="feather feather-more-vertical feather-user">
                                                    <circle cx="12" cy="12" r="1"></circle>
                                                    <circle cx="12" cy="5" r="1"></circle>
                                                    <circle cx="12" cy="19" r="1"></circle>
                                                </svg>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li className=" mt-2">
                                                    <a className="dropdown-item"  data-bs-toggle="modal" data-bs-target="#view-chalan">
                                                        <i className="fa-regular fa-eye me-2"></i> View
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item"  data-bs-toggle="modal" data-bs-target="#edit-chalan"><i data-feather="edit" className="feather-edit me-2" ></i>Edit</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item confirm-text mb-0"><i className="fa-regular fa-trash-can me-2"></i>Delete</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <h5>Chalan No : POS001</h5>
                                    <h4>Chaitanya NVN</h4>
                                    <span>Banjarahills - CC Roads</span>
                                </div>
                                <ul className="department">
                                    <li>
                                        Booked
                                        <span>23 Jul 2024</span>
                                    </li>
                                    <li>
                                        Delivery
                                        <span>29 July 2024</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                            <div className="employee-grid-profile">
                                <div className="profile-head">
                                    <label className="checkboxs">
                                        <input type="checkbox"/>
                                        <span className="checkmarks"></span>
                                    </label>
                                    <div className="profile-head-action">
                                        <span className="badge badge-linesuccess text-center w-auto me-1">Active</span>
                                        <div className="dropdown profile-action">
                                            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    className="feather feather-more-vertical feather-user">
                                                    <circle cx="12" cy="12" r="1"></circle>
                                                    <circle cx="12" cy="5" r="1"></circle>
                                                    <circle cx="12" cy="19" r="1"></circle>
                                                </svg></a>
                                            <ul className="dropdown-menu">
                                                <li className=" mt-2">
                                                    <a className="dropdown-item"  data-bs-toggle="modal" data-bs-target="#view-chalan">
                                                        <i className="fa-regular fa-eye me-2"></i> View
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item"  data-bs-toggle="modal" data-bs-target="#edit-chalan"><i data-feather="edit" className="feather-edit me-2" ></i>Edit</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item confirm-text mb-0"><i className="fa-regular fa-trash-can me-2"></i>Delete</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <h5>Chalan No : POS001</h5>
                                    <h4>Chaitanya NVN</h4>
                                    <span>Banjarahills - CC Roads</span>
                                </div>
                                <ul className="department">
                                    <li>
                                        Booked
                                        <span>23 Jul 2024</span>
                                    </li>
                                    <li>
                                        Delivery
                                        <span>29 July 2024</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <ViewChallan ChallanId={challanViewID} /> */}
        </Base>
    )
}