import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DeliveryChalan from './Components/Pages/Delivery Chalan/DeliveryChalan';
import Customers from './Components/Pages/Customers/Customers';
import Leads from './Components/Pages/Leads/Leads';
import Reminders from './Components/Pages/Reminders/Reminders';
import FollowUps from './Components/Pages/FollowUps/FollowUps';
import Calendar from './Components/Pages/Calendar/Calendar';
import ViewCustomer from './Components/Pages/Customers/ViewCustomer';
import EditCustomer from './Components/Pages/Customers/EditCustomer';
import AddCustomer from './Components/Pages/Customers/AddCustomer';
import ViewLead from './Components/Pages/Leads/ViewLead';
import EditLead from './Components/Pages/Leads/EditLead';
import AddLead from './Components/Pages/Leads/AddLead';
import Dashboard from './Components/Pages/Dashboard/Dashboard';
import LeadsReport from './Components/Pages/Reports/LeadsReports';
import CustomersReport from './Components/Pages/Reports/CustomersReport';
import RemindersReport from './Components/Pages/Reports/RemindersReport';
import FollowupsReport from './Components/Pages/Reports/FollowupsReport';
import Login from './Components/Pages/Login/Login';
import LogisticRate from './Components/Pages/LogisticRate.js/LogisticRate';
import ProjectLocations from './Components/Pages/Locations/Locations';
import Projects from './Components/Pages/Projects/Projects';
import EditProject from './Components/Pages/Projects/EditProject';
import ViewProject from './Components/Pages/Projects/ViewProject';
import AddChalan from './Components/Pages/Delivery Chalan/AddDeliveryChalan';
import Products from './Components/Pages/Products/Products';
import AddProdcut from './Components/Pages/Products/AddProduct';
import ViewChallan from './Components/Pages/Delivery Chalan/ViewChallan';
import EditChallan from './Components/Pages/Delivery Chalan/EditChallan';
import PurchaseOrder from './Components/Pages/Purchase Order/PurchaseOrders';
import AddPurchaseOrder from './Components/Pages/Purchase Order/AddPurchaseOrder';
import ViewPurchaseOrder from './Components/Pages/Purchase Order/ViewPurchaseOrder';
import Invoice from './Components/Pages/Invoice/Invoices';
import AddInvoice from './Components/Pages/Invoice/AddInvoice';
import EditPurchaseOrder from './Components/Pages/Purchase Order/EditPurchaseOrder';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="" element={<Login />} />

          <Route path="dashboard" element={<Dashboard />} />

          <Route path="delivery-challan" element={<DeliveryChalan />} />
          <Route path="add-challan" element={<AddChalan />} />
          <Route path="view-challan/:ChallanID" element={<ViewChallan />} />
          <Route path="edit-challan/:ChallanID" element={<EditChallan />} />
          <Route path="products" element={<Products />} />
          <Route path="add-product" element={<AddProdcut />} />

          <Route path="purchase-order" element={<PurchaseOrder />} />
          <Route path="add-purchase-order" element={<AddPurchaseOrder />} />
          <Route path="view-purchase-order/:PurchaseID" element={<ViewPurchaseOrder />} />
          <Route path="edit-purchase-order/:PurchaseID" element={<EditPurchaseOrder />} />

          <Route path="customers" element={<Customers />} />
          <Route path="add-customer" element={<AddCustomer />} />
          <Route path="view-customer/:cusid" element={<ViewCustomer />} />
          <Route path="edit-customer/:cusid" element={<EditCustomer />} />

          <Route path="invoice" element={<Invoice />} />
          <Route path="add-invoice" element={<AddInvoice />} />

          <Route path="leads" element={<Leads />} />
          <Route path="add-lead" element={<AddLead />} />
          <Route path="view-lead/:id" element={<ViewLead />} />
          <Route path="edit-lead/:id" element={<EditLead />} />

          <Route path="logistic-rate" element={<LogisticRate />} />
          
          <Route path="reminders" element={<Reminders />} />
          <Route path="followups" element={<FollowUps />} />
          <Route path="calendar" element={<Calendar />} />

          <Route path="projects" element={<Projects />} />
          <Route path="edit-project/:id" element={<EditProject />} />
          <Route path="view-project/:id" element={<ViewProject />} />


          <Route path="project-locations" element={<ProjectLocations />} />

          <Route path="leads-report" element={<LeadsReport />} />
          <Route path="customers-report" element={<CustomersReport />} />
          <Route path="reminders-report" element={<RemindersReport />} />
          <Route path="followups-report" element={<FollowupsReport />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
