
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import Pagination from '../../Config/Pagination';
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link, useNavigate } from "react-router-dom";
import '../../Config/search.css';
import '../../Config/loader.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../../Config/loading.css';
import Swal from "sweetalert2";
import '../../Config/swal.css';


export default function Invoice() {
    const navigate = useNavigate();

    const [productsData, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [productViewOBJ, setProductViewObj] = useState([]);
    const [productEditOBJ, setProductEditObj] = useState([]);

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (!userDetails) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetInvoices`);
                    if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setProducts(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setProducts([]);
                }
            } catch (error) {
                    console.error("Error fetching data:", error);
            } finally {
                    setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const showProductViewModal = (obj) => {
        setProductViewObj(obj);
    };

    const showProductEditModal = (obj) => {
        setProductEditObj(obj);
    };

    const toggleActiveStatus = async (Id, currentStatus) => {
        try {
            const response = await fetch(`${API_BASE_CRM_URL}ActiveProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Id: Id, active: currentStatus ? 0 : 1 }),
            });

            const result = await response.json();

            if (result.Status) {
                // Update the local state to reflect the change
                setProducts((prevProducts) =>
                    prevProducts.map((prod) =>
                        prod.id === Id ? { ...prod, active: currentStatus ? 0 : 1 } : prod
                    )
                );
                Swal.fire('Success', 'Product status updated successfully', 'success');
            } else {
                Swal.fire('Error', 'Failed to update product status', 'error');
            }
        } catch (error) {
            console.error('Error updating product status:', error);
            Swal.fire('Error', 'An error occurred while updating product status', 'error');
        }
    };

    const formatDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const itemsPerPage = 10;

    const filteredItems = productsData.filter(item => 
        item.number.toString().includes(searchTerm.toLowerCase())
    );
    
    // Calculate indices for slicing data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleExcelDownload = () => {
        if (productsData.length === 0) {
            return; // Don't download if there is no data
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert your data to worksheet format
        const wsData = productsData.map((item) => ({
            'Name': item.description,
            'Unit': item.unit,
            'Rate': item.rate,
            'Tax1': `${item.tax1name} (${item.tax1rate}%)`,
            'Tax2': `${item.tax2name} (${item.tax2rate}%)`,
            'Description': item.long_description,
        }));

        const ws = XLSX.utils.json_to_sheet(wsData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'productsData');

        // Save the workbook to a file
        XLSX.writeFile(wb, 'productsData.xlsx');
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();

        // Set properties for PDF
        doc.setProperties({
            title: 'Products Data',
            author: 'Your Name',
            creator: 'Your Application'
        });

        // Add data to PDF
        const tableData = []; // Your data array here

        // Add a header row
        const headers = ['Name', 'Rate', 'Units', 'Tax1', 'Tax2', 'Description'];
        tableData.push(headers);

        // Add rows
        productsData.forEach(item => {
            const row = [];
            row.push(item.description, item.rate, item.unit, `${item.tax1name} (${item.tax1rate}%)`, `${item.tax2name} (${item.tax2rate}%)`, item.long_description);
            tableData.push(row);
        });

        // Generate table
        doc.autoTable({
            head: [headers],
            body: tableData.slice(1),
        });

        // Save the PDF
        doc.save('productsData.pdf');
    };
    

    return (
        <Base>
            
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Invoices</h4>
                        <h6>Manage your invoices</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <div className="search-container">
                            <input 
                                type="text" 
                                name="search" 
                                placeholder="Search..." 
                                className="search-input"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <a href="#" className="search-btn">
                                <i className="fas fa-search fs-6"></i>      
                            </a>
                        </div>                  
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf" onClick={handlePDFDownload}><img src="assets/img/icons/pdf.svg" alt="img"/></a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel" onClick={handleExcelDownload}><img src="assets/img/icons/excel.svg" alt="img"/></a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"><i className="fa-solid fa-print fs-5"></i></a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"><i className="fa-solid fa-arrow-rotate-left fs-5"></i></a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Collapse" id="collapse-header"><i className="fa-solid fa-chevron-up fs-6"></i></a>
                    </li>
                    <li>
                        <Link to='/add-invoice' className="btn btn-added"><i className="fa-solid fa-plus"></i></Link>
                    </li>
                </ul>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    <div className="table-responsive product-list d-none d-lg-block d-md-block">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="no-sort">#</th>
                                    <th className="no-sort">Action</th>
                                    <th>Number</th>
                                    <th>Amount</th>
                                    <th>Total Tax</th>
                                    <th>Date</th>
                                    <th>Customer</th>
                                    <th>Project</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? 
                                    <td colSpan={12} className="text-center">
                                        <p className="text-center">
                                            <div class="containers m-auto">
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                                <div class="dot"></div>
                                            </div>
                                        </p>
                                    </td>
                                :
                                currentItems && currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                           {indexOfFirstItem + index + 1}
                                        </td>
                                        <td className="text-start">
                                            <div className="dropdown">
                                                <a className="action-set" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                </a>
                                                <div className="dropdown-menu">
                                                    <a
                                                        className="dropdown-item" 
                                                        data-bs-toggle="offcanvas" 
                                                        data-bs-target="#offcanvasView" 
                                                        aria-controls="offcanvasView"
                                                        onClick={() => showProductViewModal(item)}
                                                    >
                                                        <i className="fa-regular fa-eye me-2"></i>View
                                                    </a>
                                                    <a  
                                                        className={`dropdown-item ${item.active ? '' : 'disabled'}`}
                                                        data-bs-toggle="offcanvas" 
                                                        data-bs-target="#offcanvasEdit" 
                                                        aria-controls="offcanvasEdit"
                                                        onClick={() => showProductEditModal(item)}
                                                    >
                                                        <i className="fa-regular fa-pen-to-square text-info me-2"></i>Edit 
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        onClick={() => toggleActiveStatus(item.id, item.active)}
                                                    >
                                                        <i className={`fa-solid fa-toggle-${item.active ? 'off text-danger' : 'on text-success'} me-2`}></i>
                                                        {item.active ? 'Deactivate' : 'Activate'}
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>INV-{item.number}</td>
                                        <td>{item.totalamount ? item.totalamount : 'N/A'}</td>
                                        <td>{item.totaltax ? item.totaltax : 'N/A'}</td>
                                        <td>{formatDate(item.date)}</td>
                                        <td>{item.cus_name ? item.cus_name : 'N/A'}</td>
                                        <td>{item.proj_name ? item.proj_name : 'N/A'}</td>
                                        <td>{formatDate(item.duedate)}</td>
                                        <td>
                                            {item.active === 1 ? (
                                                <span className="badge badge-linesuccess ms-2">Active</span>
                                            ) : (
                                                <span className="badge badge-linedanger ms-2">Inactive</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={productsData.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>

                    {/* Mobile View */}
                    <div className="row d-block d-md-none d-lg-none" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        {loading ? 
                            <p className="text-center mt-5">
                                <div class="containers m-auto">
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                </div>
                            </p>
                        :
                            currentItems && currentItems.map((item, index) => (
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6" key={index}>
                                <div className="employee-grid-profile">
                                    <div className="profile-head">
                                        <label className="checkboxs">
                                            <input type="checkbox"/>
                                            <span className="checkmarks"></span>
                                        </label>
                                        <div className="profile-head-action">
                                            {item.active === 1 ? (
                                                // <span className="badge bg-outline-success ms-2">Active</span>
                                                <span className="badge badge-linesuccess text-center w-auto me-1">Active</span>
                                            ) : (
                                                <span className="badge badge-linedanger text-center w-auto me-1">Inactive</span>
                                                )}
                                            <div className="dropdown profile-action">
                                                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 27 24" fill="none"
                                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                        className="feather feather-more-vertical feather-user">
                                                        <circle cx="12" cy="12" r="1"></circle>
                                                        <circle cx="12" cy="5" r="1"></circle>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </svg>
                                                </a>
                                                <ul className="dropdown-menu" style={{ width: '8.5rem' }}>
                                                    <a
                                                        className="dropdown-item" 
                                                        data-bs-toggle="offcanvas" 
                                                        data-bs-target="#offcanvasView" 
                                                        aria-controls="offcanvasView"
                                                        onClick={() => showProductViewModal(item)}
                                                    >
                                                        <i className="fa-regular fa-eye me-2"></i>View
                                                    </a>
                                                    <a  
                                                        className={`dropdown-item ${item.active ? '' : 'disabled'}`}
                                                        data-bs-toggle="offcanvas" 
                                                        data-bs-target="#offcanvasEdit" 
                                                        aria-controls="offcanvasEdit"
                                                        onClick={() => showProductEditModal(item)}
                                                    >
                                                        <i className="fa-regular fa-pen-to-square text-info me-2"></i>Edit 
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        onClick={() => toggleActiveStatus(item.id, item.active)}
                                                    >
                                                        <i className={`fa-solid fa-toggle-${item.active ? 'off text-danger' : 'on text-success'} me-2`}></i>
                                                        {item.active ? 'Deactivate' : 'Activate'}
                                                    </a>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        <table className="table">
                                            <tbody>
                                                <tr className="text-start">
                                                    <td>{item.description}</td>
                                                    <td>{item.rate}</td>
                                                </tr>
                                                <tr className="text-start">
                                                    <td>{item.tax1name} {item.tax1rate}</td>
                                                    <td>{item.tax2name} {item.tax2rate}</td>
                                                    {/* <td>{item.company}</td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <ul className="department d-felx flex-column">
                                        <label className="form-label">Description:</label>
                                        {item.long_description}
                                    </ul>
                                </div>
                            </div>
                         ))}
                    </div>
                </div>
            </div>
        </Base>
    )
}